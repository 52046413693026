<!--
 * @Author: your name
 * @Date: 2021-11-19 11:32:48
 * @LastEditTime: 2021-11-19 16:12:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/views/lesson_plan/index.vue
-->
<template>
  <div class="plan_view">
    <router-view />
  </div>
</template>
<script>

export default {
  
};
</script>
<style>
    .plan_view{
        width: 100%;
        height: 100%;
    }
</style>